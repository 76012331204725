import React from "react"
import { Link } from "react-router-dom"

const Footer = () => {
	return (
		<section className="footer wf-section">
			<div className="container footer-container">
				<div className="footer-wrapper">
					<div className="footer-block">
						<Link to={"/"} className="footer-brand w-inline-block">
							<img
								src="images/restia-black.svg"
								loading="lazy"
								className="footer-brand-image"
							/>
						</Link>
						<Link to={"/#"} className="footer-link footer-link-big">
							Domů
						</Link>
						<Link
							to={"/produkty#"}
							className="footer-link footer-link-big">
							Produkty
						</Link>
						{/* <Link
							to={"/faq"}
							className="footer-link footer-link-big">
							FAQ
						</Link> */}
						<Link
							to={"/kontakt"}
							className="footer-link footer-link-big">
							Kontakty
						</Link>
						{/* <a href="#" className="footer-link footer-link-big">
							Podmínky použití
						</a>
						<a href="#" className="footer-link footer-link-big">
							Reklamační řád
						</a> */}
					</div>
					<div className="footer-block">
						<div className="footer-section">
							<div className="footer-title">Showroom RESTIA</div>
							<div className="footer-link">
								Jablonecká 17/9,
								<br />
								460 07 Liberec
							</div>
						</div>
						<div className="footer-section">
							<div className="footer-title">Kontaktujte nás</div>
							<div className="footer-link">
								t.:{" "}
								<a
									href="tel:+420778980006"
									className="footer-link">
									+420 778 980 006
								</a>
								<br />
								e.:{" "}
								<a
									href="mailto:info@restia.cz"
									className="footer-link">
									info@restia.cz
								</a>
							</div>
						</div>
						<div className="footer-section">
							<div className="footer-title">Firemní údaje</div>
							<div className="footer-link">
								Americká 662/70,
								<br />
								460 07 Liberec
								<br />
							</div>
							<div className="footer-link">
								IČ: 05960789
								<br />
								DIČ: CZ05960789
								<br />
								Datová schránka: k7zf699
							</div>
						</div>
					</div>
					{/* <div className="footer-block footer-block-right">
						<div className="footer-form w-form">
							<form
								id="wf-form-Footer-Form"
								name="wf-form-Footer-Form"
								data-name="Footer Form"
								method="get"
								className="footer-form-container">
								<div className="footer-title">
									Chcete o nás vědět vše?
								</div>
								<div className="footer-link">
									Přihlaste se k odběru našeho newsletteru a
									nic Vám neunikne.
									<br />
								</div>
								<div className="footer-form-block">
									<input
										type="email"
										className="footer-form-field w-input"
										maxLength={256}
										name="Footer-Email-2"
										data-name="Footer Email 2"
										aria-label="Váš e-mail"
										placeholder="Váš e-mail"
										id="Footer-Email-2"
										required
									/>
									<input
										type="submit"
										value="Odeslat"
										data-wait="Please wait..."
										className="button-outlined footer-button-outlined w-button"
									/>
								</div>
								<label className="w-checkbox checkbox-field">
									<input
										type="checkbox"
										id="checkbox"
										name="checkbox"
										data-name="Checkbox"
										className="w-checkbox-input checkbox"
									/>
									<span className="checkbox-label w-form-label">
										Souhlasím se zpracováním osobních údajů
									</span>
								</label>
							</form>
							<div className="w-form-done">
								<div>
									Thank you! Your submission has been
									received!
								</div>
							</div>
							<div className="w-form-fail">
								<div>
									Oops! Something went wrong while submitting
									the form.
								</div>
							</div>
						</div>
					</div> */}
				</div>
			</div>
			<div className="footer-copy">
				<div className="footer-copy-text">© 2023 RESTIA s. r. o.</div>
			</div>
		</section>
	)
}

export default Footer
