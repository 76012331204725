import React from "react"
import logo from "./logo.svg"
import "./App.scss"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ProduktyView, FaqView, IndexView } from "./views"
import KontaktView from "./views/KontaktView"
import ScrollToTop from "./ScrollToTop"

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route index element={<IndexView />} />
					{/* <Route path="faq" element={<FaqView />} /> */}
					<Route path="produkty" element={<ProduktyView />} />
					<Route path="kontakt" element={<KontaktView />} />
				</Routes>
			</BrowserRouter>
		</div>
	)
}

export default App
