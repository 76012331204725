import React, { useEffect, useState, useRef } from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import DocumentMeta from "react-document-meta"
import "react-toastify/dist/ReactToastify.css"
import ContactForm from "../components/ContactForm"

const scripts = [
	{
		loading: fetch(
			"https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63553f7f4f3fdf4d450fd417"
		).then((body) => body.text()),
		isAsync: false,
	},
	{
		loading: fetch("js/webflow.js").then((body) => body.text()),
		isAsync: false,
	},
]

const KontaktView = () => {
	useEffect(() => {
		const htmlEl = document.querySelector("html")
		htmlEl.dataset["wfPage"] = "637042ef4679d85524409e3c"
		htmlEl.dataset["wfSite"] = "63553f7f4f3fdf4d450fd417"

		scripts.concat(null).reduce((active, next) =>
			Promise.resolve(active).then((active) => {
				const loading = active.loading.then((script) => {
					new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

					return next
				})

				return active.isAsync ? next : loading
			})
		)
	}, [])

	return (
		<span>
			<DocumentMeta title={"Kontakt"} />
			<style
				dangerouslySetInnerHTML={{
					__html: `
          @import url(C:\\css\\normalize.css);
          @import url(C:\\css\\webflow.css);
          @import url(C:\\css\\restia.webflow.css);
        `,
				}}
			/>
			<span className="af-view">
				<div>
					<img
						src="images/path4722.svg"
						loading="lazy"
						alt="logo"
						className="header-pic"
					/>
					<Header title={"Kontakt"} selectedPage={"kontakty"} />
					<ContactForm hasMarginTop={true} />
					<Footer />
				</div>
			</span>
		</span>
	)
}

export default KontaktView
