import React from "react"
import { Link } from "react-router-dom"

interface HeaderProps {
	title: string
	selectedPage: string
}

const Header = (props: HeaderProps) => {
	const links = [
		{ title: "domů", href: "/" },
		{ title: "produkty", href: "/produkty#" },
		// { title: "FAQ", href: "/faq" },
		{ title: "kontakty", href: "/kontakt#" },
	]

	const [title, selectedPage] = [props.title, props.selectedPage]
	return (
		<div
			data-animation="default"
			data-collapse="medium"
			data-duration={400}
			data-easing="ease"
			data-easing2="ease"
			role="banner"
			className="navbar-logo-left-container shadow-three w-nav">
			<div className="container header-container">
				<div className="navbar-wrapper">
					<Link to="/" className="navbar-brand w-nav-brand">
						<img
							src="images/restia-white.svg"
							loading="lazy"
							className="image-8"
						/>
					</Link>
					<nav
						role="navigation"
						className="nav-menu-wrapper w-nav-menu">
						<ul role="list" className="nav-menu w-list-unstyled">
							{links.map((link) => (
								<li className="list-item">
									<Link
										to={link.href}
										className={`nav-link ${
											selectedPage === link.title
												? "w--current"
												: ""
										}`}>
										{link.title}
									</Link>
								</li>
							))}

							<li className="nav-link-accent">
								<a
									id="login"
									href="https://lite.restia.cz"
									className="button button-login w-inline-block">
									<img
										src="images/person.svg"
										loading="lazy"
										className="image-3"
									/>
									<div className="text-block">login</div>
								</a>
							</li>
						</ul>
					</nav>
					<div className="menu-button menu-button-2 w-nav-button">
						<div className="w-icon-nav-menu" />
					</div>
				</div>
				<h1 className="heading-1">{title}</h1>
			</div>
		</div>
	)
}

export default Header
